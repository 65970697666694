import React from 'react'
import Layout from '../components/layout'
import WebDevelopment from '../components/categories/web-development'
import Seo from '../components/seo'

const WebDevelopmentPage = ({ location }) => {
  return (
    <Layout location={location}>
      <WebDevelopment />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Web Development Freelancers | Codestaff'
  const description =
    'Hire the best Web Development freelancers at Codestaff. Get the top 1% of Web Development professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default WebDevelopmentPage
