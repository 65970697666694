import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const MainWrapper = styled.div`
margin: 0;
`
const productMeta = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "Freelance Web Developer",
    "image": "https://codestaff.io/web-development.png",
    "description": "Freelance Web Developers at Code Staff have all of the skill necessary to help your next project succeed. Hire a Remote Web Developer and have them join your team.",
    "brand": "Code Staff",
    "offers": {
        "@type": "Offer",
        "url": "https://codestaff.io/web-development",
        "priceCurrency": "USD",
        "price": "99",
        "priceValidUntil": "2020-05-27",
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "1",
        "reviewCount": "1"
    },
    "review": {
        "@type": "Review",
        "name": "FinTech",
        "reviewBody": "Quick and efficient!",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1"
        },
        "datePublished": "2020-05-06",
        "author": { "@type": "Person", "name": "FinTech" },
        "publisher": { "@type": "Organization", "name": "Freelancer Website" }
    }
}

const faqMeta = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Who do I hire for Web Development",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "When Hiring a Web Developer from Code Staff your web development project is in good hands. Code Staff is the best place to hire a web developer."
        }
    }
}

const WebDevelopment = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire the top freelance<br /><span> Web Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top web developers. Top
                                    companies and start-ups choose Codestaff web development
                                    professionals for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top web developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE WEB DEVELOPERS AND ENGINEERS' banner='/web-development.png' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default WebDevelopment